.rte-button[aria-checked="false"] {
    /* Material / mui grey.400 */
    border-color: #bdbdbd !important;
}

.rte-button[aria-checked="true"] {
    /** secondary.main color from theme */
    background: #254aa5;
    border-color: #254aa5 !important;
}

/* Button images are black by default. For checked buttons, invert them to be white */
.rte-button[aria-checked="true"] > span {
    filter: invert(100%);
}
